import React, { useState, useEffect } from "react";

import Accordion from "../accordion/accordion";
import Input from "../form/input/input";
import Button from "../button/button";
import TickIcon from "../svg/tick";
import QuestionMarkIcon from "../svg/questionMark";

import * as styles from "./calculator.module.css";
import Tooltip from "../tooltip/tooltip";
import Curve from "../svg/curve";
import Slider from "../form/slider/slider";
import {
  costIntegerToString,
  stringCostToInteger,
} from "../../utils/calcUtils";

import {
CONFIG
} from "../../configuration/config";

import {
  COPY
} from "../../configuration/copy";

const Calculator = () => {
  const [intervalCost, setIntervalCost] = useState(0);
  const [timeToRepay, setTimeToRepay] = useState(0);
  const [revenue, setRevenue] = useState(null);
  const [loanAmount, setLoanAmount] = useState({
    step: 500,
    min: 1000,
    max: CONFIG.LOAN_AMOUNT_MAX,
    value: 0,
  });
  const [sweep, setSweep] = useState({
    step: 1,
    min: 1,
    max: 25,
    value: 1,
  });
  const [loanAmountAnimation, setLoanAmountAnimation] = useState(false);
  const [sweepAnimation, setSweepAnimation] = useState(false);

  const shouldShowFields = revenue > CONFIG.REVENUE_MIN;
  const outputSliderSlideClass = shouldShowFields ? styles.outputSliderActive : "";


  // Revenue determines the loan amount ceiling
  useEffect(() => {
    if (revenue) {
      let maxLoan = revenue * 2;
      maxLoan = Math.ceil(maxLoan / loanAmount.step) * loanAmount.step;
      maxLoan = maxLoan > CONFIG.LOAN_AMOUNT_MAX ? CONFIG.LOAN_AMOUNT_MAX : maxLoan;

      if (shouldShowFields) {
        setLoanAmountAnimation(true);
        setSweepAnimation(true);
        setSweep(calculateSweep());
      }

      setLoanAmount({
        ...loanAmount,
        value: maxLoan,
        max: maxLoan
      });
    }
  }, [revenue]);

  // Loan amount determines the sweep floor
  useEffect(() => {
    if (revenue && shouldShowFields) {
      setSweep(calculateSweep());
      setSweepAnimation(true);
    }
  }, [loanAmount.value]);

  // Both loan amount and sweep value adjustments determine outputs shown
  useEffect(() => {
    if (revenue) {
      calculateOutputs();
    }
  }, [loanAmount.value, sweep.value]);


  const totalRepayment = loanAmount.value + (loanAmount.value / 100) * CONFIG.FIXED_FEE;
  const calculateOutputs = () => {

    const intervalCostCalc = revenue * (sweep.value / 100) > totalRepayment ? totalRepayment : revenue * (sweep.value / 100);

    setIntervalCost(Math.trunc(intervalCostCalc));
    setTimeToRepay(Math.ceil(totalRepayment / intervalCostCalc));
  }
  
  const calculateSweep = () => {

    let minSweep = Math.round(
      ((totalRepayment * (1 + CONFIG.FIXED_FEE)) / (revenue * 12)) * 10
    );

    minSweep = minSweep < CONFIG.SALES_PERCENTAGE_MIN ? CONFIG.SALES_PERCENTAGE_MIN :
      (
        minSweep > CONFIG.SALES_PERCENTAGE_MAX ? CONFIG.SALES_PERCENTAGE_MAX : minSweep
      );

    return {
      ...sweep,
      value: minSweep,
      min: minSweep
    };
  };


  const handleCostInputChange = (value, callback) => {
    const startsWithNumber = /^\d/.test(value);

    if (value === "" || startsWithNumber === false) {
      return callback("");
    }

    return callback(stringCostToInteger(value));
  };

  const handleRangeInputBlur = (input, min, max, callback) => {
    const value = input === false ? min : stringCostToInteger(input);
    const callable = value < min ? min : (value > max) ? max : value;

    return callback(callable);
  };

  return (
    <div className={styles.root}>
      <div className={styles.controls}>
        <form className={styles.form}>
          <Accordion className={styles.accordion} expanded>
            <Accordion.Trigger className={styles.accordionTrigger}>
              <h2 className={styles.accordionHeading}>{COPY.INPUTS.REVENUE_TITLE}</h2>
            </Accordion.Trigger>

            <Accordion.Content>
              <div className={styles.accordionContentInner}>
                <div className={styles.salesInputWrapper}>
                  <Input
                    type="tel"
                    maxLength="8"
                    flash={false}
                    placeholder={COPY.INPUTS.REVENUE_PLACEHOLDER}
                    required
                    value={costIntegerToString(revenue)}
                    onChange={(e) => {
                      handleCostInputChange(e.target.value, (output) => {
                        setRevenue(output);
                      });
                    }}
                  />
                </div>
              </div>
            </Accordion.Content>
          </Accordion>

          <Accordion
            className={styles.accordion}
            expanded={shouldShowFields}
            flash={loanAmountAnimation}
            onTransitionEnd={() => setLoanAmountAnimation(false)}
          >
            <Accordion.Trigger
              className={styles.accordionTrigger}
              disabled={!shouldShowFields}
            >
              <h2 className={styles.accordionHeading}>{COPY.INPUTS.LOAN_AMOUNT_TITLE}</h2>
            </Accordion.Trigger>

            <Accordion.Content>
              <div className={styles.accordionContentInner}>
                <div className={`${loanAmountAnimation ? styles.flash : ""} ${styles.costInputWrapper}`}>
                  <Input
                    type="tel"
                    maxLength="7"
                    flash={loanAmountAnimation}
                    onTransitionEnd={() => setLoanAmountAnimation(false)}
                    required
                    readOnly
                    value={costIntegerToString(loanAmount.value)}
                    onChange={(e) => {
                      handleCostInputChange(e.target.value, (output) => {
                        setLoanAmount({ ...loanAmount, value: output });
                      });
                    }}
                    onBlur={(e) => {
                      handleRangeInputBlur(
                        e.target.value,
                        loanAmount.min,
                        loanAmount.max,
                        (output) => {
                          setLoanAmount({ ...loanAmount, value: output });
                        }
                      );
                    }}
                  />
                </div>

                <Slider
                  {...loanAmount}
                  onChange={(value) => {
                    setLoanAmount({ ...loanAmount, value });
                  }}
                  minLabel={`£${loanAmount.min / 1000}K`}
                  maxLabel={`£${loanAmount.max / 1000}K`}
                  flash={false}
                />
              </div>
            </Accordion.Content>
          </Accordion>

          <Accordion
            className={styles.accordion}
            expanded={shouldShowFields}
            flash={sweepAnimation}
            onTransitionEnd={() => setSweepAnimation(false)}
          >
            <Accordion.Trigger
              className={styles.accordionTrigger}
              disabled={!shouldShowFields}
            >
              <h2 className={styles.accordionHeading}>{COPY.INPUTS.SWEEP_TITLE}</h2>

              <Tooltip content={COPY.INPUTS.SWEEP_TOOLTIP}>
                <QuestionMarkIcon />
              </Tooltip>
            </Accordion.Trigger>

            <Accordion.Content>
              <div className={styles.accordionContentInner}>
                <div
                  className={`${styles.percentageInputWrapper} ${
                    sweep.value.toString().length === 2
                      ? styles.percentageInputWrapperWide
                      : ""
                  } ${sweepAnimation ? styles.flash : ""}`}
                >
                  <Input
                    type="tel"
                    maxLength="2"
                    flash={sweepAnimation}
                    required
                    readOnly
                    value={sweep.value}
                    onTransitionEnd={() => setSweepAnimation(false)}
                    onChange={(e) => {
                      handleCostInputChange(e.target.value, (output) => {
                        setSweep({
                          ...sweep,
                          value: output,
                        });
                      });
                    }}
                    onBlur={(e) => {
                      handleRangeInputBlur(
                        e.target.value,
                        sweep.min,
                        sweep.max,
                        (output) => {
                          setSweep({
                            ...sweep,
                            value: output,
                          });
                        }
                      );
                    }}
                  />
                </div>

                <Slider
                  {...sweep}
                  onChange={(value) => {
                    setSweep({ ...sweep, value });
                  }}
                  minLabel={`${sweep.min}%`}
                  maxLabel={`${sweep.max}%`}
                  flash={sweepAnimation}
                />
              </div>
            </Accordion.Content>
          </Accordion>
        </form>
      </div>

      <div className={styles.output}>
        <Curve className={styles.mobileDivider} />

        <div className={`${styles.outputSlider} ${outputSliderSlideClass}`}>
          <div className={`${styles.outputPanel} ${styles.benefitsPanel}`}>
            <h2 className={styles.benefitsHeading}>
              {COPY.OUTPUTS.SECTION_TITLE}
            </h2>

            <ul className={styles.outputList}>
              <li>
                <TickIcon />
                <div>
                  <h2 className={styles.outputListHeading}>{COPY.OUTPUTS.PRELOAD_BULLETS.FIRST}</h2>
                  <p className={styles.outputListDescription}>{COPY.OUTPUTS.PRELOAD_BULLETS.FIRST_SUB}</p>
                </div>

              </li>
              <li>
                <TickIcon />
                <div>
                  <h2 className={styles.outputListHeading}>{COPY.OUTPUTS.PRELOAD_BULLETS.SECOND}</h2>
                  <p className={styles.outputListDescription}>{COPY.OUTPUTS.PRELOAD_BULLETS.SECOND_SUB}</p>
                </div>

              </li>
              <li>
                <TickIcon />
                <div>
                  <h2 className={styles.outputListHeading}>{COPY.OUTPUTS.PRELOAD_BULLETS.THIRD}</h2>
                  <p className={styles.outputListDescription}>{COPY.OUTPUTS.PRELOAD_BULLETS.THIRD_SUB}</p>
                </div>

              </li>
            </ul>
          </div>

          <div className={`${styles.outputPanel} ${styles.costsPanel}`}>
            <ul className={styles.outputList}>
              <li>
                <Tooltip content={COPY.OUTPUTS.ESTIMATED_REPAYMENTS_TOOLTIP}>
                  <QuestionMarkIcon />
                </Tooltip>

                <h2 className={styles.outputListHeading}>
                  {COPY.OUTPUTS.ESTIMATED_REPAYMENTS_TITLE}
                </h2>

                <span className={styles.outputListFigure}>
                  £<b>{intervalCost.toLocaleString()}</b>
                </span>
              </li>

              <li>
                <Tooltip content={COPY.OUTPUTS.TOTAL_COST_TOOLTIP}>
                  <QuestionMarkIcon />
                </Tooltip>

                <div>
                  <h2 className={styles.outputListHeading}>
                    {COPY.OUTPUTS.TOTAL_COST_TITLE}
                  </h2>

                  <p className={styles.outputListDescription}>
                    {COPY.OUTPUTS.TOTAL_COST_SUBTITLE}
                  </p>
                </div>

                <span className={styles.outputListFigure}>
                  £<b>{totalRepayment.toLocaleString()}</b>
                </span>
              </li>

              <li>
                <Tooltip content={COPY.OUTPUTS.TIME_TO_REPAY_TOOLTIP}>
                  <QuestionMarkIcon />
                </Tooltip>

                <h2 className={styles.outputListHeading}>
                  {COPY.OUTPUTS.TIME_TO_REPAY_TITLE}
                </h2>

                <span className={styles.outputListFigure}>
                  <b>{timeToRepay}</b> {CONFIG.REPAY_FREQUENCY}{timeToRepay > 1 ? 's' : ''}
                </span>
              </li>
            </ul>
          </div>
        </div>

        <Button className={styles.applyButton} link={CONFIG.BUTTON_URL}>
          {COPY.OUTPUTS.BUTTON_COPY}
        </Button>
      </div>
    </div>
  );
};

export default Calculator;
