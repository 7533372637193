import React from "react";
import RcSlider from "rc-slider";
import "rc-slider/assets/index.css";

import * as styles from "./slider.module.css";

const Slider = ({
  min,
  max,
  step,
  value,
  onChange,
  minLabel,
  maxLabel,
  flash,
  ...rest
}) => {
  return (
    <div className={styles.root} {...rest}>
      <RcSlider
        className={styles.slider}
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={onChange}
      />

      <div className={styles.labels}>
        <span className={`${styles.minMaxLabel} ${flash ? styles.flash : ""}`}>{minLabel}</span>
        <span className={styles.minMaxLabel}>{maxLabel}</span>
      </div>
    </div>
  );
};

export default Slider;
