import * as appStyles from "./app.module.css";
import Calculator from "./components/calculator/calculator";

export function App() {
  return (
    <div className={appStyles.container}>
      <div className={appStyles.inner}>
        <h1>Iwoca Calculator</h1>
        <Calculator />
        <div id="tooltip-portal" />
      </div>
    </div>
  );
}
