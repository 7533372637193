import React, { useState } from "react";
import ReactDOM from "react-dom";
import { v4 as uuidv4 } from "uuid";
import { usePopper } from "react-popper";

import * as styles from "./tooltip.module.css";

const Tooltip = ({ content, position = "top", children }) => {
  const [isActive, setIsActive] = useState(false);
  const [domReady, setDomReady] = useState(false);

  React.useEffect(() => {
    setDomReady(true);
  });

  const id = uuidv4();

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const {
    styles: popperStyles,
    attributes,
    update,
  } = usePopper(referenceElement, popperElement, {
    placement: position,
    modifiers: [
      { name: "offset", options: { offset: [0, 12] } },
      { name: "arrow", options: { element: arrowElement } },
    ],
  });

  return (
    <>
      <button
        type="button"
        ref={setReferenceElement}
        aria-controls={id}
        className={styles.trigger}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            setIsActive(!isActive);
          }
        }}
        onBlur={() => {
          setIsActive(false);
        }}
        onMouseEnter={async () => {
          await update();
          setIsActive(true);
        }}
        onMouseLeave={() => {
          setIsActive(false);
        }}
      >
        {children}
      </button>

      {domReady &&
        ReactDOM.createPortal(
          <div
            ref={setPopperElement}
            id={id}
            aria-hidden={!isActive}
            className={styles.popper}
            style={popperStyles.popper}
            {...attributes.popper}
          >
            {content}
            <div
              ref={setArrowElement}
              className={styles.arrow}
              style={popperStyles.arrow}
            />
          </div>,
          document.querySelector("#tooltip-portal")
        )}
    </>
  );
};

export default Tooltip;
