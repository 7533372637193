import React from "react";

import * as styles from "./button.module.css";

const Button = ({ type = "button", className, link, children, ...rest }) => {
  return (
    <a href={link} type={type} className={`${styles.root} ${className}`} {...rest}>
      {children}
    </a>
  );
};

export default Button;
