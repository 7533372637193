/**
 * Given an integer, return a string representation of the integer as a cost.
 * @example
 * // returns 50,000
 * costIntegerToString(50000);
 * @param {number} cost
 * @returns {string}
 */
export function costIntegerToString(cost) {
  if (cost === null) {
    return "";
  }

  return cost.toLocaleString();
}

/**
 * Given a string, return an integer representation of the string as a cost.
 * @example
 * // returns 2500
 * stringCostToInteger(2,500);
 * @param {string} string
 * @returns {number}
 */
export function stringCostToInteger(string) {
  const stringWithoutCommas = string.replace(/\D/g, "");
  return parseInt(stringWithoutCommas);
}
