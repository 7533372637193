import React from "react";

const TickIcon = ({ ...rest }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <circle cx="10" cy="10" r="10" fill="white" />
      <path
        d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM10 16.25C9.25 16.25 8.75 15.75 8.75 15C8.75 14.25 9.25 13.75 10 13.75C10.75 13.75 11.25 14.25 11.25 15C11.25 15.75 10.75 16.25 10 16.25ZM11.875 10.5C11.25 10.875 11.25 11 11.25 11.25V12.5H8.75V11.25C8.75 9.625 9.75 8.875 10.5 8.375C11.125 8 11.25 7.875 11.25 7.5C11.25 6.75 10.75 6.25 10 6.25C9.5 6.25 9.125 6.5 8.875 6.875L8.25 8L6.125 6.75L6.75 5.625C7.375 4.5 8.625 3.75 10 3.75C12.125 3.75 13.75 5.375 13.75 7.5C13.75 9.375 12.625 10 11.875 10.5Z"
        fill="#256AC0"
      />
    </svg>
  );
};

export default TickIcon;
