import React from "react";
import * as styles from "./accordionContent.module.css";

const AccordionContent = ({ isActive, children, className, ...rest }) => {
  const activeClass = isActive ? styles.isActive : "";

  return (
    <div className={`${styles.root} ${activeClass} ${className}`} {...rest}>
      {children}
    </div>
  );
};

export default AccordionContent;
