// Configurable parameters
export const CONFIG = {
  FIXED_FEE: 10,
  REPAY_FREQUENCY: 'month', // toggle week|month to adjust calculations and relevant copy

  REVENUE_MIN: 999,
  LOAN_AMOUNT_MAX: 50000,
  SALES_PERCENTAGE_MIN: 1,
  SALES_PERCENTAGE_MAX: 25,

  BUTTON_URL: "https://google.com"
};

