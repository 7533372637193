import React, { useRef } from "react";
import { v4 as uuidv4 } from "uuid";

import * as styles from "./accordion.module.css";
import AccordionTrigger from "./trigger/accordionTrigger";
import AccordionContent from "./content/accordionContent";

const Accordion = ({
  flash = false,
  children,
  expanded = false,
  className,
  onTransitionEnd,
  ...rest
}) => {
  const id = uuidv4();

  const rootRef = useRef(null);

  const compoundChildren = React.Children.map(children, (child) => {
    switch (child.type) {
      case AccordionTrigger: {
        return React.cloneElement(child, {
          "aria-expanded": expanded,
          "aria-controls": id,
        });
      }

      case AccordionContent: {
        return React.cloneElement(child, {
          id,
          isActive: expanded,
          "aria-hidden": !expanded,
        });
      }

      default:
        return child;
    }
  });

  return (
    <div
      ref={rootRef}
      className={`${styles.root} ${flash ? styles.flash : ""} ${className}`}
      onTransitionEnd={(e) => {
        if (e.target === rootRef.current && onTransitionEnd) {
          onTransitionEnd();
        }
      }}
      {...rest}
    >
      {compoundChildren}
    </div>
  );
};

Accordion.Trigger = AccordionTrigger;
Accordion.Content = AccordionContent;

export default Accordion;
