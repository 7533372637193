import React from "react";

const TickIcon = ({ ...rest }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <circle cx="12" cy="12" r="10" fill="white" />
      <path
        d="M2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.522 17.523 22 12 22C6.477 22 2 17.522 2 12ZM15.294 8.293L10.001 13.586L7.707 11.292C7.61475 11.1965 7.50441 11.1203 7.3824 11.0679C7.2604 11.0155 7.12918 10.9879 6.9964 10.9867C6.86362 10.9856 6.73194 11.0109 6.60905 11.0612C6.48615 11.1115 6.3745 11.1857 6.2806 11.2796C6.18671 11.3735 6.11246 11.4851 6.06218 11.608C6.0119 11.7309 5.9866 11.8626 5.98775 11.9954C5.9889 12.1282 6.01649 12.2594 6.0689 12.3814C6.12131 12.5034 6.19749 12.6138 6.293 12.706L9.294 15.707C9.48153 15.8945 9.73584 15.9998 10.001 15.9998C10.2662 15.9998 10.5205 15.8945 10.708 15.707L16.708 9.707C16.8035 9.61475 16.8797 9.50441 16.9321 9.3824C16.9845 9.2604 17.0121 9.12918 17.0133 8.9964C17.0144 8.86362 16.9891 8.73194 16.9388 8.60905C16.8885 8.48615 16.8143 8.3745 16.7204 8.28061C16.6265 8.18671 16.5149 8.11246 16.392 8.06218C16.2691 8.0119 16.1374 7.9866 16.0046 7.98775C15.8718 7.9889 15.7406 8.01649 15.6186 8.0689C15.4966 8.12131 15.3862 8.19749 15.294 8.293V8.293Z"
        fill="#00B378"
      />
    </svg>
  );
};

export default TickIcon;
