import React from "react";

import * as styles from "./input.module.css";

const FormInput = ({ flash, ...rest }) => {
  return <input
    className={`${styles.root} ${flash ? styles.flash : ""}`}
    {...rest}
  />;
};

export default FormInput;
