import {CONFIG} from "./config";
import {costIntegerToString} from "../utils/calcUtils";
import React from "react";

export const COPY = {

  INPUTS: {
    REVENUE_TITLE: "Your monthly card and online sales",
    REVENUE_PLACEHOLDER: `${costIntegerToString(CONFIG.REVENUE_MIN + 1)} min`,
    LOAN_AMOUNT_TITLE: "Loan amount",
    SWEEP_TITLE: "Percentage of sales to repay with",
    SWEEP_TOOLTIP: `We calculate your ${CONFIG.REPAY_FREQUENCY}ly repayments as a percentage of your eligible sales - payments your customers make online or by card.`,
  },

  OUTPUTS: {
    SECTION_TITLE: "All about our Revenue Based Loan",
    PRELOAD_BULLETS: {
      FIRST: `£1,000 - £${costIntegerToString(CONFIG.LOAN_AMOUNT_MAX)}`,
      FIRST_SUB: "Up to 2 months turnover",
      SECOND: "Fixed fee only",
      SECOND_SUB: "No interest",
      THIRD: "Repay based on card or online sales",
      THIRD_SUB: ""
    },
    ESTIMATED_REPAYMENTS_TITLE: `Estimated ${CONFIG.REPAY_FREQUENCY}ly repayment`,
    ESTIMATED_REPAYMENTS_TOOLTIP: `There's no fixed ${CONFIG.REPAY_FREQUENCY}ly repayment amount. Instead, you’ll repay your loan each ${CONFIG.REPAY_FREQUENCY} with a fixed percentage of your eligible sales – more in busy ${CONFIG.REPAY_FREQUENCY}s, less when sales are slow.`,
    TOTAL_COST_TITLE: "Total repayment",
    TOTAL_COST_TOOLTIP: `Your total loan amount will include a one-off fixed fee, no interest. This loan calculator is only an example; we've used  ${CONFIG.FIXED_FEE}%, but your fixed fee will change depending on your circumstances.`,
    TOTAL_COST_SUBTITLE: `Includes a ${CONFIG.FIXED_FEE}% fixed fee`,
    TIME_TO_REPAY_TITLE: "Estimated time to repay",
    TIME_TO_REPAY_TOOLTIP: "This is how long it could take you to repay, based on your eligible monthly sales today - your performance will affect how long you repay for.",
    BUTTON_COPY: "Apply now"
  }

};


