import React from "react";

const Curve = ({ ...rest }) => {
  return (
    <svg
      width="343"
      height="32"
      viewBox="0 0 343 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      {...rest}
    >
      <path
        d="M343 0.000174522C343 17.6733 266.217 32.0002 171.5 32.0002C76.7832 32.0002 0 17.6733 0 0.000174522L171.5 0L343 0.000174522Z"
        fill="white"
      />
    </svg>
  );
};

export default Curve;
